import Layout from "../../components/reusable/Layout";

import classes from "./UserServicesPage.module.css";
import ServiceSection from "./ServiceSection";
// import superhumanLogo from '../../images/superhuman_logo.png'

type Props = {
  data: any;
  isRestoreReport: boolean;
};
const UserServicesPage = ({ data, isRestoreReport }: Props) => {
  // console.log('USER_SERVICES_PAGE',data)
  const totalItem = [...data.SERVICES, ...data.IVSERVICES];
  const numOfPages = Math.ceil(totalItem.length / 4);

  const displayedItems = [];

  for (let i = 0; i < data.SERVICES.length; i++) {
    if (displayedItems.length < 4) {
      displayedItems.push({
        isOnFirstPage: isRestoreReport ? true : false,
        type: "UserService",
        ...data.SERVICES[i],
      });
    } else {
      displayedItems.push({
        isOnFirstPage: false,
        type: "UserService",
        ...data.SERVICES[i],
      });
    }
  }

  for (let i = 0; i < data.IVSERVICES.length; i++) {
    if (displayedItems.length < 4) {
      displayedItems.push({
        isOnFirstPage: isRestoreReport ? true : false,
        type: "IVService",
        ...data.IVSERVICES[i],
      });
    } else {
      displayedItems.push({
        isOnFirstPage: false,
        type: "IVService",
        ...data.IVSERVICES[i],
      });
    }
  }

  return (
    <Layout customHeight={`${numOfPages * 1123}px`}>
      {isRestoreReport && (
        <div className={classes.restoreTitle}>Restore Therapies</div>
      )}
      {displayedItems.map((service: any, index: number) => (
        <ServiceSection
          key={index}
          data={service}
          type={service.type}
          isRestoreReport={isRestoreReport}
        />
      ))}
    </Layout>
  );
};

export default UserServicesPage;
